import React from "react";
import { Container } from "react-bootstrap";
import WebHeader from "../common/WebHeader";

const TopHeroSection = () => {
  return (
    <div className="bg-white  d-flex flex-column">
      <div className="">
        <WebHeader displaynone="d-none" />
      </div>
      <Container className="py-5 d-flex flex-column justify-content-center flex-grow-1 align-items-center">
        <div className="custom_maxW mx-auto py-5">
          <h1 className=" text-center color_orange manrope_FF mb-0">
            Welcome to BituConnect
          </h1>
          <h2 className=" text-center manrope_FF mb-0 mt-3">
            Your Gateway to the Global Bitumen Market
          </h2>
          <p className=" text-center manrope_FF mb-0 mt-3">
            At BituConnect, powered by Bitutrading FZ-LLC, we simplify the
            process of connecting buyers and vendors in the global bitumen
            industry. Whether you are looking to source quality bitumen or
            expand your customer base as a supplier, BituConnect ensures a
            seamless and secure transaction experience.
          </p>
          <div className="d-flex align-items-center gap-4 justify-content-center mt-4">
            {/* <button className="px-4 py-2 rounded-2 bg_theme border-0 text-white manrope_FF">
              Book a Demo
            </button> */}
            <button className="px-4 py-2 rounded-2  border border-2 text-black manrope_FF">
              Create a Free Account
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeroSection;
