import React from "react";
import footer_image from "../../assets/png/footer_image.png";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="footer_bg py-4">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <p className=" manrope_FF text-white mb-0 text-center">
            © 2024 All Rights Reserved |{" "}
            <span className="color_orange">Bituconnect.com</span>
          </p>
          <div className="d-flex align-items-center gap-4">
            <p className="mb-0 cusor_pointer text-white">Terms of Service </p>
            <p className="mb-0 cusor_pointer text-white">Privacy Policy</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
