import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/svg/home_footer_icon.svg";
import facebook from "../../assets/svg/facebook_icon.svg";
import since_icon from "../../assets/svg/Since_icon.svg";
import youtube_icon from "../../assets/svg/youtube_icon.svg";
import x from "../../assets/svg/x_icon.svg";
import in_icon from "../../assets/svg/in_icon.svg";

const HomeFooter = () => {
  return (
    <div className="homefooter_bg text-start pt-5 pb-3">
      <Container className="pt-5">
        <Row className="pt-md-5 pt-2">
          <Col lg={3} md={6} className="mt-4 mt-lg-0">
            <img src={logo} alt="logo" />
            <p className=" manrope_FF color_gray my-4 fw-semibold">
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsums.
            </p>
            <img src={since_icon} alt="since_icon" />
          </Col>
          <Col lg={3} md={6} className="mt-4 mt-lg-0">
            <h4 className="fs_20 fw-bold manrope_FF text-white">
              Useful Links
            </h4>
            <ul className="text-start ps-0 mt-3">
              <li className="mt-3">
                <a className=" fs_semibold manrope_FF  color_gray" href="#">
                  {" "}
                  About Us
                </a>
              </li>
              <li className="mt-3">
                <a className=" fs_semibold manrope_FF  color_gray" href="#">
                  {" "}
                  Contact Us
                </a>
              </li>
              <li className="mt-3">
                <a className=" fs_semibold manrope_FF  color_gray" href="#">
                  {" "}
                  Terms of use
                </a>
              </li>
              <li className="mt-3">
                <a className=" fs_semibold manrope_FF  color_gray" href="#">
                  {" "}
                  Careers
                </a>
              </li>
              <li className="mt-3">
                <a className=" fs_semibold manrope_FF  color_gray" href="#">
                  {" "}
                  Privacy Policy
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={6} className="mt-4 mt-lg-0">
            <h4 className="fs_20 fw-bold manrope_FF text-white">
              Office Address
            </h4>
            <p className=" manrope_FF color_gray fw-normal">
              42 Mamnoun Street, Saba Carpet and Antiques Store, UK
            </p>
            <h4 className="fs_20 fw-bold manrope_FF text-white">
              Email Address
            </h4>
            <p className=" manrope_FF color_gray fw-normal">
              Interested in working with us? support.Bitutrading@gmail.com
            </p>
          </Col>
          <Col lg={3} md={6} className="mt-4 mt-lg-0">
            <h4 className="fs_20 fw-bold manrope_FF text-white">
              Phone Number
            </h4>
            <p className=" manrope_FF color_gray fw-normal">+971 551 579 261</p>
            <p className=" manrope_FF color_gray fw-normal">+97 155 596 1659</p>
            <h4 className="fs_20 fw-bold manrope_FF text-white">Follow Us</h4>
            <div className="d-flex mt-4 align-items-center gap-3">
              <span
                className="px-2 py-1 rounded-1"
                style={{ background: "#1A1A19" }}
              >
                <img src={facebook} alt="facebook" />
              </span>
              <span
                className="px-2 py-1 rounded-1"
                style={{ background: "#1A1A19" }}
              >
                <img src={x} alt="facebook" />
              </span>
              <span
                className="px-2 py-1 rounded-1"
                style={{ background: "#1A1A19" }}
              >
                <img src={youtube_icon} alt="facebook" />
              </span>
              <span
                className="px-2 py-1 rounded-1"
                style={{ background: "#1A1A19" }}
              >
                <img src={in_icon} alt="facebook" />
              </span>
              <span
                className="px-2 py-1 rounded-1"
                style={{ background: "#1A1A19" }}
              >
                <img src={facebook} alt="facebook" />
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="border1 mt-5"></div>
      <p className=" fs_semibold mb-0 mt-3 text-center manrope_FF text-white">
        © 2024 All Rights Reserved |{" "}
        <span className="color_orange">Bitutrading.com</span>
      </p>
    </div>
  );
  <Col></Col>;
};

export default HomeFooter;
