import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import about_image from "../../assets/png/about_img.png";
import about_icon from "../../assets/svg/about_icon.svg";
import border_right_icon from "../../assets/png/Border_right_icon.svg";

const Bitutrading = () => {
  const [activeTab, setActiveTab] = useState("history");
  return (
    <div className="py-5">
      <Container>
        <Row className="justify-content-between ">
          <Col md={6}>
            <img className="w-100" src={about_image} alt="about_image" />
          </Col>
          <Col md={6} className="ps-md-5">
            <img src={about_icon} alt="about_icon" />
            <h5 className=" fs_47 fw-bold py-2 manrope_FF">
              We work for you since 1989. Bitutrading around the world
            </h5>
            <p>
              Welcome to Bitutrading, a leading industry innovator with a rich
              history of excellence.
            </p>
            <div className="tabs-container bg-gray align-items-center rounded-2 mt-5 row pb-3 pt-0">
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "history"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("history")}
              >
                <h6 className="mb-0 pt-3">Our History</h6>
              </div>
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "mission"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("mission")}
              >
                <h6 className="mb-0 pt-3">Our Mission</h6>
              </div>
              <div
                className={`tab col-4 border-end cusor_pointer  d-flex justify-content-center align-items-center ${
                  activeTab === "vision"
                    ? "active color_orange border-top custom_border"
                    : ""
                }`}
                onClick={() => setActiveTab("vision")}
              >
                <h6 className="mb-0 pt-3">Our Vision</h6>
              </div>
            </div>
            <div className="tab-content mt-4">
              {activeTab === "history" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Welcome to Industrie, a leading industry innovator with a rich
                  history of excellence. With a passion for precision and a
                  commitment to quality, we have been empowering industries and
                  driving progress.
                </p>
              )}
              {activeTab === "mission" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Our mission is to provide high-quality products and services
                  that exceed customer expectations, foster innovation, and
                  drive sustainable growth in the industry.
                </p>
              )}
              {activeTab === "vision" && (
                <p className="manrope_FF fs_16 color_gray mb-0">
                  Our vision is to be a global leader in the industry,
                  recognized for our commitment to excellence, innovation, and
                  sustainable practices.
                </p>
              )}
            </div>
            <div className="additional-info d-flex  align-items-center gap-3 mt-4">
              <div className="d-flex align-items-center gap-2">
                <img src={border_right_icon} alt="border_right_icon" />
                <p className="mb-0 fw-bold  manrope_FF fs_16 color_gray">
                  Quality Control System
                </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={border_right_icon} alt="border_right_icon" />
                <p className="mb-0  fw-bold manrope_FF fs_16 color_gray">
                  Advanced Technology
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Bitutrading;
