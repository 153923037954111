import React from "react";
import text_image from "../../assets/png/text_image.svg";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";

const OurProducts = () => {
  return (
    <div className="py-5" style={{ backgroundColor: "#F7F7F7" }}>
      <Container className=" text-center">
        <img src={text_image} alt="text_image" />
        <h4 className="fs_47 fw-bold manrope_FF mt-2">
          Do You Have Questions<br></br> For{" "}
          <span className=" color_orange">Our Products</span>
        </h4>
        {/* <p className="fs_16 manrope_FF color_gray mt-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod
          <br></br>
          tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
        <Accordion
          defaultActiveKey="0"
          className="faq_width mt-5 mx-auto manrope_FF text-start"
        >
          <Accordion.Item className="mt-4" show eventKey="0">
            <Accordion.Header className="">
              <h5 className="mb-0 fs_16"> 01. What is BituConnect?</h5>
            </Accordion.Header>
            <Accordion.Body>
              BituConnect is an online platform that connects buyers and vendors
              for the purchase of bitumen, providing tools for requesting
              quotes, managing orders, and tracking shipments.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="1">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">02. How do I create an account?</h5>
            </Accordion.Header>
            <Accordion.Body>
              You can create an account by clicking the "Sign Up" button on the
              homepage and filling in the required details.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="2">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">03. How do I place an order?</h5>
            </Accordion.Header>
            <Accordion.Body>
              Once logged in, click "Create Order," enter your bitumen
              specifications, port of discharge, quantity, and preferred
              packing. Vendors will respond with quotes that you can accept,
              decline, or request a price revision.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="3">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">04. Can I track my shipment?</h5>
            </Accordion.Header>
            <Accordion.Body>
              Yes, use the 'Track My Cargo' feature by entering your Bill of
              Lading (BL) number to view real-time updates on your shipment.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="4">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">05. How do I manage my documents?</h5>
            </Accordion.Header>
            <Accordion.Body>
              You can upload and manage documents like Commercial Invoices (CI)
              in the 'My Documents' section of your account.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="5">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">
                06. Can I negotiate prices with vendors?
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              Yes, you can request a price revision up to two times for each
              order before finalizing the deal.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-4" eventKey="6">
            <Accordion.Header>
              <h5 className="mb-0 fs_16">07. How do I contact support?</h5>
            </Accordion.Header>
            <Accordion.Body>
              For assistance, you can email us at support@bituconnect.com.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
};

export default OurProducts;
