import React from "react";
import question_icon from "../../assets/svg/Question_icon.svg";
import container_img from "../../assets/png/Container_img.png";
import { Accordion, Col, Container, Row } from "react-bootstrap";

const Question = () => {
  return (
    <div className="py-5">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={question_icon} alt="question_icon" />
            <h4 className="fs_47 mt-3 manrope_FF fw-bold ">
              Do You Have Questions<br></br> For{" "}
              <span className=" color_orange">Our Products</span>
            </h4>
            <p className="mt-3 fs_15 manrope_FF">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
              eiusmod
              <br></br>
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <Accordion
              defaultActiveKey="0"
              className="faq_width mt-5 mx-auto manrope_FF text-start"
            >
              <Accordion.Item className="mt-4" eventKey="0">
                <Accordion.Header className="">
                  <h5 className="mb-0 fs_16">
                    {" "}
                    01. Can i use green energy in my home or business?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  Randomised words which don’t look even slightly believable. If
                  you are going passage of you need sure there anything.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mt-4" eventKey="1">
                <Accordion.Header>
                  <h5 className="mb-0 fs_16">
                    02. How do i get started with green energy?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mt-4" eventKey="2">
                <Accordion.Header>
                  <h5 className="mb-0 fs_16">
                    03. Green energy reliable during bad weather?
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={6}>
            <img
              className="w-100 mt-4 mt-md-0"
              src={container_img}
              alt="container_img"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Question;
